import React, { Component } from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import { graphql } from 'gatsby'
// import aboutImg from "../images/default.jpg"

export class About extends Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const { text, name, image } = this.props.data.allFakerApiCustoms.nodes[0]

    return (
      <div>
      <Helmet title={siteTitle} />
      <Layout>
        <section className="about-us pad-70">
          <div className="container">
            
            <div className="row">

                <div className="col-lg-4">
                  <div className="image-container">
                    <img src={image} alt={name} />
                  </div>
                </div>

                <div className="col-lg-8">
                  <h4>{name}</h4>
                  
                  <p>{text}</p>
                </div>
             
            </div>
          </div>
        </section>
      </Layout>
      </div>
    )
  }
}

export default About

export const pageQuery = graphql`
  query AboutQuery {
   
        site {
          siteMetadata {
            title
            description
          }
        }

        allFakerApiCustoms {
          nodes {
            text
            name
            image
            id
          }
        }
  }
`


